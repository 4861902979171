import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

import environment from '@/.config/environment';

const {
    hostName: { supergraph },
} = environment(window);

const httpLink = new HttpLink({ uri: supergraph });

export const superGraphClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export const setLink = (authToken) => {
    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${authToken}`,
            },
        }));
        return forward(operation);
    });

    superGraphClient.setLink(concat(authMiddleware, httpLink));
};

/**
 * Hook for preparing the client with token for Auth to make requests to SuperGraph server.
 */
export const usePrepareClient = () => {
    const [authToken, setAuthToken] = useState(null);
    useEffect(() => {
        async function getIdToken() {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            setAuthToken(token);
        }
        getIdToken();
    }, []);

    if (authToken) {
        setLink(authToken);
        return true;
    }
    return false;
};

export const prepareClient = async () => {
    const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    setLink(authToken);
    return true;
};
