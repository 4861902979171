import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'classDetail.pageTitle',
        defaultMessage: 'Class details - AWS Training and Certification',
    },
    editButtonText: {
        id: 'classMenu.editButtonText',
        defaultMessage: 'Edit',
    },
    editButtonLabel: {
        id: 'classMenu.editButtonLabel',
        defaultMessage: 'Edit class details',
    },
    createClassButtonText: {
        id: 'classMenu.createClassButtonText',
        defaultMessage: 'Create new class',
    },
    viewButtonLabel: {
        id: 'classMenu.viewButtonLabel',
        defaultMessage: 'View class details',
    },
    physicalClassLabel: {
        id: 'classLocationTypes.physicalClassLabel',
        defaultMessage: 'In-person classroom',
    },
    virtualClassLabel: {
        id: 'classLocationTypes.virtualLabel',
        defaultMessage: 'Virtual classroom',
    },
    classInformationHeader: {
        id: 'classDetail.classInformationHeader',
        defaultMessage: 'Class information',
    },
    courseTitleLabel: {
        id: 'classDetail.courseTitleLabel',
        defaultMessage: 'Course title',
    },
    courseVersionLabel: {
        id: 'classDetail.courseVersionLabel',
        defaultMessage: 'Version',
    },
    courseContentVersionLabel: {
        id: 'classDetail.courseContentVersionLabel',
        defaultMessage: 'Content Version',
    },
    courseLanguageLabel: {
        id: 'classDetail.courseLanguageLabel',
        defaultMessage: 'Language',
    },
    dateLabel: {
        id: 'classDetail.dateLabel',
        defaultMessage: 'Date',
    },
    timeLabel: {
        id: 'classDetail.timeLabel',
        defaultMessage: 'Time',
    },
    durationLabel: {
        id: 'classDetail.durationLabel',
        defaultMessage: 'Duration',
    },
    timeZoneLabel: {
        id: 'classDetail.timeZoneLabel',
        defaultMessage: 'Time zone',
    },
    trainingProviderLabel: {
        id: 'classDetail.trainingProviderLabel',
        defaultMessage: 'Training provider',
    },
    trainingPartnerLabel: {
        id: 'classDetail.trainingPartnerLabel',
        defaultMessage: 'Training partner',
    },
    classLocationHeader: {
        id: 'classDetail.classLocationHeader',
        defaultMessage: 'Class location',
    },
    classAddressLabel: {
        id: 'classDetail.classAddressLabel',
        defaultMessage: 'Address',
    },
    instructorUrlLabel: {
        id: 'classDetail.instructorUrlLabel',
        defaultMessage: 'Instructor-view URL',
    },
    studentUrlLabel: {
        id: 'classDetail.studentUrlLabel',
        defaultMessage: 'Student-view URL',
    },
    classNumberOfStudents: {
        id: 'classDetail.classNumberOfStudents',
        defaultMessage: 'Number of students',
    },
    classCreatedBy: {
        id: 'classDetail.classCreatedBy',
        defaultMessage: 'Created by',
    },
    classInstructor: {
        id: 'classDetail.classInstructor',
        defaultMessage: 'Instructor',
    },
    copyToClipboard: {
        id: 'classDetail.copyToClipboard',
        defaultMessage: 'Copy URL',
    },
    urlCopied: {
        id: 'classDetail.urlCopied',
        defaultMessage: 'Copied',
    },
    classLoading: {
        id: 'classDetail.classLoading',
        defaultMessage: 'Loading...',
    },
    virtualClassUrlLabel: {
        id: 'classDetail.virtualClassUrlLabel',
        defaultMessage: 'Virtual classroom URL',
    },
    modalHeader: {
        id: 'classDetail.modalHeader',
        defaultMessage: 'Please copy and save your instructor-view URL',
    },
    modalConfirmButton: {
        id: 'classDetail.modalConfirmButton',
        defaultMessage: 'Confirm',
    },
    instructorUrlModalNotice: {
        id: 'classDetail.instructorUrlModalNotice',
        defaultMessage: 'Copy and save URL to access your class',
    },
    openVirtualClassUrlLabel: {
        id: 'classDetail.openVirtualClassUrlLabel',
        defaultMessage: 'Open virtual classroom URL',
    },
    classStudentRosterHeader: {
        id: 'classDetail.classStudentRosterHeader',
        defaultMessage: 'Student roster',
    },
    addStudentListButton: {
        id: 'classDetail.addStudentListButton',
        defaultMessage: 'Add student list',
    },
    classAttendanceButtonText: {
        id: 'classMenu.classAttendanceButtonText',
        defaultMessage: 'Attendance',
    },
    classAttendanceButtonLabel: {
        id: 'classMenu.classAttendanceButtonLabel',
        defaultMessage: 'View and report class attendance',
    },
});

export default messages;
