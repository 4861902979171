import { Icon } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { LabToggle } from '@/components';
import messages from '@/containers/classPages/ClassDetailPage/ClassDetailPage.messages';

import './CardContainer.scss';

const CardContainer = ({
    title,
    contentId,
    duration,
    description,
    index,
    children,
    statusLabel,
}) => {
    const { formatMessage } = useIntl();
    const { classroomId } = useParams();

    return (
        <li className='card-container' data-testid={`card-container-${index}`}>
            <div className={`card-container__header ${statusLabel ? 'with-status-label' : ''}`}>
                <div>
                    <h3>{title}</h3>
                    {duration ? (
                        <span
                            className='card-container__icon'
                            role='img'
                            aria-label={formatMessage(messages.durationLabel)}
                        >
                            <Icon
                                name='status-pending'
                                variant='subtle'
                                data-testid='duration-icon'
                            />
                            &nbsp; {duration}
                        </span>
                    ) : null}
                </div>
                {statusLabel}
            </div>
            <p>{description}</p>
            <hr />
            <div className='card-container__actions'>
                {children}
                <div className='card-container__toggle'>
                    <LabToggle labArn={contentId} classroomId={classroomId} />
                </div>
            </div>
        </li>
    );
};

export default CardContainer;
