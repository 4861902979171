import { Box, CollectionPreferences } from '@amzn/awsui-components-react-v3';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

import countries from '@/components/classForm/countries.messages';
import { timezones } from '@/components/timeZoneSelect/TimeZoneSelect.messages';
import { paths, dateToLanguage } from '@/utils';

import { messages } from './ClassTable.messages';

/**
 * Defines every possible column and its corresponding ids.
 */
export const COLUMN_IDS = {
    courseTitle: 'courseTitle',
    startDate: 'startDate',
    endDate: 'endDate',
    startTime: 'startTime',
    timeZone: 'timeZone',
    country: 'country',
    deliveryMethod: 'deliveryMethod',
    classStatus: 'classStatus',
    instructor: 'instructors',
    createdBy: 'createdBy',
};

/**
 * Time units that can be used for time manipulation with Moment library.
 * Time units is also compatible with Polaris 3.0 DateRangePicker TimeUnit.
 * @type {{MONTH: string, YEAR: string, HOUR: string, WEEK: string, SECOND: string, DAY: string, MINUTE: string}}
 */
export const TIME_UNITS = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
};

/**
 * Builds and returns a set of configurations used to render Classroom listing table.
 * columnDefinitions contains the definitions of all possible columns in the table (@see getColumnDefinitions)
 * visibleColumnOptions contains the list of columns that "can" be visible, allowing users to select columns in this set to be visible.
 * pageSizeOptions contains the list of available page sizes (i.e. number of class shows per page) to choose from.
 * paginationLabel contains the labels used by the pagination UI component.
 * filterConfig contains dates used as boundaries for filtering purposes.
 */
export const getConfiguration = (intl, _isUserTrainingCoordinator, archivedMode = false) => {
    const filterConfig = getFilterConfig(archivedMode);
    return {
        columnDefinitions: getColumnDefinitions(intl, filterConfig.referenceMoment),
        visibleColumnOptions: getVisibleColumnOptions(intl.formatMessage),
        pageSizeOptions: getPageSizeOptions(intl.formatMessage),
        paginationLabel: getPaginationLabel(intl.formatMessage),
        filterConfig,
    };
};

/**
 * Defines and returns definitions of all possible columns.
 * This is the superset of columns that are used to display classroom listing for all type of users.
 * @param intl object of type IntlShape (https://formatjs.io/docs/react-intl/api/#intlshape) for localization
 */
export const getColumnDefinitions = (intl, _referenceMoment) => {
    const { formatMessage } = intl;

    const headerLabel = (title, sortedState) => {
        return `${title}, ${
            sortedState.sorted
                ? `sorted ${sortedState.descending ? 'descending' : 'ascending'}`
                : 'not sorted'
        }.`;
    };

    const classStatusLabel = (classStartDate, classEndDate) => {
        const now = moment();
        if (classStartDate.isBefore(now) && classEndDate.isAfter(now)) {
            return formatMessage(messages.classStatusActive);
        } else if (classStartDate.isAfter(now)) {
            return formatMessage(messages.classStatusUpcoming);
        } else {
            return formatMessage(messages.classStatusArchived);
        }
    };

    const executeWithFallbackOnError = (fnToExecute, onErrorFallbackValue = '') => {
        try {
            return fnToExecute();
        } catch (e) {
            return onErrorFallbackValue;
        }
    };

    return [
        {
            id: COLUMN_IDS.courseTitle,
            sortingField: COLUMN_IDS.courseTitle,
            header: formatMessage(messages.courseTitle),
            cell: (item) => (
                <Link
                    data-testid='class-list__to-detail-page'
                    to={paths.classDetailPage(encodeURIComponent(item.classroomId))}
                    aria-label={formatMessage(messages.courseTitleAriaLabel, {
                        title: item.courseTitle,
                        startDate: dateToLanguage(item.startDate),
                        endDate: dateToLanguage(item.endDate),
                    })}
                >
                    {item.courseTitle && item.courseTitle.length > 0 ? item.courseTitle : '-'}
                </Link>
            ),
            ariaLabel: (sortState) => headerLabel(formatMessage(messages.courseTitle), sortState),
            minWidth: 100,
        },
        {
            id: COLUMN_IDS.startDate,
            sortingField: COLUMN_IDS.startDate,
            header: formatMessage(messages.startDate),
            cell: (item) => executeWithFallbackOnError(() => item.startDate.format('YYYY-MM-DD')),
            ariaLabel: (sortState) => headerLabel(formatMessage(messages.startDate), sortState),
            width: 130,
        },
        {
            id: COLUMN_IDS.startTime,
            header: formatMessage(messages.classTimeLabel),
            cell: (item) => executeWithFallbackOnError(() => item.startDate.format('HH:mm')),
            width: 80,
        },
        {
            id: COLUMN_IDS.endDate,
            sortingField: COLUMN_IDS.endDate,
            header: formatMessage(messages.endsOnDate),
            cell: (item) =>
                executeWithFallbackOnError(() => item.endDate.format('YYYY-MM-DD HH:mm')),
            ariaLabel: (sortState) => headerLabel(formatMessage(messages.endsOnDate), sortState),
        },
        {
            id: COLUMN_IDS.timeZone,
            header: formatMessage(messages.timezone),
            cell: (item) =>
                executeWithFallbackOnError(() =>
                    formatMessage(timezones[item.locationData.timezone]),
                ),
            ariaLabel: (sortState) => headerLabel(formatMessage(messages.timezone), sortState),
        },
        {
            id: COLUMN_IDS.country,
            header: formatMessage(messages.country),
            cell: (item) =>
                executeWithFallbackOnError(() =>
                    item.locationData.physicalAddress?.country
                        ? formatMessage(countries[item.locationData.physicalAddress?.country])
                        : '-',
                ),
            ariaLabel: (sortState) => headerLabel(formatMessage(messages.country), sortState),
        },
        {
            id: COLUMN_IDS.deliveryMethod,
            header: formatMessage(messages.deliveryMethod),
            cell: (item) =>
                executeWithFallbackOnError(() =>
                    formatMessage(messages[`${item.locationData.locationType}Location`]),
                ),
            ariaLabel: (sortState) =>
                headerLabel(formatMessage(messages.deliveryMethod), sortState),
        },
        {
            id: COLUMN_IDS.classStatus,
            header: formatMessage(messages.classStatusLabel),
            cell: (item) =>
                executeWithFallbackOnError(() => classStatusLabel(item.startDate, item.endDate)),
            ariaLabel: (sortState) =>
                headerLabel(formatMessage(messages.classStatusLabel), sortState),
        },
        {
            id: COLUMN_IDS.instructor,
            header: formatMessage(messages.instructorList),
            cell: (item) => {
                const instructors = item.instructors || [];
                return (
                    <>
                        {instructors.map((instructor) => (
                            <Box key={`${item.classroomId}${instructor}`} display={'block'}>
                                {instructor}
                            </Box>
                        ))}
                    </>
                );
            },
        },
        {
            id: COLUMN_IDS.createdBy,
            header: formatMessage(messages.createdBy),
            cell: (item) => (
                <Box variant='awsui-key-label' display='inline'>
                    <span title={item.createdBy}>{item.createdBy}</span>
                </Box>
            ),
        },
    ];
};

/**
 * Builds and returns the set of columns that can be configured to be visible.
 * CourseTitle and startDate are always visible and cannot be toggled off by the user.
 */
const getVisibleColumnOptions = (formatMessage) => {
    return [
        {
            label: formatMessage(messages.preferencesSelectVisibleColumnLabel),
            options: [
                {
                    id: COLUMN_IDS.courseTitle,
                    label: formatMessage(messages.courseTitle),
                    editable: false,
                },
                {
                    id: COLUMN_IDS.startDate,
                    label: formatMessage(messages.startDate),
                    editable: false,
                },
                { id: COLUMN_IDS.startTime, label: formatMessage(messages.classTimeLabel) },
                { id: COLUMN_IDS.endDate, label: formatMessage(messages.endsOnDate) },
                { id: COLUMN_IDS.timeZone, label: formatMessage(messages.timezone) },
                { id: COLUMN_IDS.country, label: formatMessage(messages.country) },
                { id: COLUMN_IDS.deliveryMethod, label: formatMessage(messages.deliveryMethod) },
                { id: COLUMN_IDS.classStatus, label: formatMessage(messages.classStatusLabel) },
                { id: COLUMN_IDS.instructor, label: formatMessage(messages.instructorList) },
                { id: COLUMN_IDS.createdBy, label: formatMessage(messages.createdBy) },
            ],
        },
    ];
};

export const getPageSizeOptions = (formatMessage) => [
    { value: 25, label: formatMessage(messages.preferencesPageSizeOptionLabel, { pageSize: 25 }) },
    { value: 50, label: formatMessage(messages.preferencesPageSizeOptionLabel, { pageSize: 50 }) },
    {
        value: 100,
        label: formatMessage(messages.preferencesPageSizeOptionLabel, { pageSize: 100 }),
    },
    {
        value: 200,
        label: formatMessage(messages.preferencesPageSizeOptionLabel, { pageSize: 200 }),
    },
];

const getPaginationLabel = (formatMessage) => {
    return {
        nextPageLabel: formatMessage(messages.nextPageLabel),
        pageLabel: (pageNumber) => {
            formatMessage(messages.pageLabel, { pageNumber });
        },
        previousPageLabel: formatMessage(messages.previousPageLabel),
    };
};

export const getFilterConfig = (archivedMode = false) => {
    /**
     * Since active classrooms include classrooms that started in the past and end date in the future,
     * allows to look back certain duration in the past to look for active classes when not in archiveMode
     */
    const startDateLowerBoundary = archivedMode
        ? { amount: -1, unit: TIME_UNITS.YEAR }
        : { amount: -10, unit: TIME_UNITS.YEAR };

    return {
        archivedMode,
        referenceMoment: moment().startOf(TIME_UNITS.DAY),
        /**
         * Absolute max time range for querying.
         */
        boundaries: {
            [COLUMN_IDS.startDate]: {
                lower: startDateLowerBoundary,
                upper: {
                    amount: archivedMode ? 0 : 1,
                    unit: TIME_UNITS.YEAR,
                },
            },
            [COLUMN_IDS.endDate]: {
                lower: {
                    amount: archivedMode ? -1 : 0,
                    unit: TIME_UNITS.YEAR,
                },
                upper: {
                    amount: archivedMode ? 0 : 1,
                    unit: TIME_UNITS.YEAR,
                },
            },
        },
        /**
         * Initial offset is the duration to be used for startDate in query when page is rendered.
         */
        initialOffset: {
            [COLUMN_IDS.startDate]: {
                after: archivedMode
                    ? {
                          amount: -30,
                          unit: TIME_UNITS.DAY,
                      }
                    : {
                          amount: -10,
                          unit: TIME_UNITS.YEAR,
                      },
                before: {
                    amount: archivedMode ? 0 : 1,
                    unit: TIME_UNITS.DAY,
                },
            },
        },
    };
};

/**
 * Returns fully configured CollectionPreferences component that is rendered when the user chooses to customize
 * the rendering of class listing table.  Enables users to select the set of visible columns, size of page (i.e.
 * how many classrooms to display on a single page).
 */
export const ClassroomListPreferences = ({
    preferences,
    setPreferences,
    disabled,
    pageSizeOptions,
    visibleContentOptions,
    formatMessage,
}) => (
    <CollectionPreferences
        title={formatMessage(messages.preferencesLabel)}
        confirmLabel={formatMessage(messages.preferencesConfirm)}
        cancelLabel={formatMessage(messages.preferencesCancel)}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
            title: formatMessage(messages.preferencesPageSizeLabel),
            options: pageSizeOptions,
        }}
        visibleContentPreference={{
            title: formatMessage(messages.preferencesSelectVisibleColumnHeader),
            options: visibleContentOptions,
        }}
    />
);
