import '@amzn/awsui-components-react/index.css';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ComponentDidCatch, ScrollToTop, Locale } from '@/components';
import App from '@/containers/app/App';
import { ClassroomProvider } from '@/data/ClassroomContext';
import { configureAmplify } from '@/modules';
import { superGraphClient } from '@/modules/supergraph/client';
import { FlagContextProvider } from '@/utils';

configureAmplify();

const Root = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <FlagContextProvider>
                <ApolloProvider client={superGraphClient}>
                    <Locale>
                        <ComponentDidCatch>
                            <ClassroomProvider>
                                <App />
                            </ClassroomProvider>
                        </ComponentDidCatch>
                    </Locale>
                </ApolloProvider>
            </FlagContextProvider>
        </BrowserRouter>
    );
};

const root = createRoot(document.getElementById('root'));
root.render(<Root />);
