import { Button } from '@amzn/awsui-components-react-v3';
import moment from 'moment';
import 'moment/min/locales';
import { useIntl } from 'react-intl';

import { CardContainer } from '@/components';
import JamStatusCardLabel from '@/components/jamStatusCardLabel/JamStatusCardLabel';

import messages from './JamCard.messages';

import './JamCard.scss';

const { jamCardLabel, jamCardDescription, jamCardFacilitateLabel } = messages;

/**
 * Renders a CardContainer that is specific to Jam. Will render the event status, an event url if present, and lab toggle.
 * @param props.courseTitle - the title of the course, from which the Jam title will be derived
 * @param props.jamIndex - index of this Jam within a list of Jams
 * @param props.jamTraining - the training object for the Jam to render
 */
const JamCard = ({ courseTitle, jamIndex, jamTraining }) => {
    const { formatMessage, locale } = useIntl();

    const {
        contentId,
        fulfillmentStatus,
        metaData: { eventStatus = '', startsOn = 0, endsOn = 0, eventURL = '' },
    } = jamTraining;

    const title = `${formatMessage(jamCardLabel)} - ${courseTitle}`;
    const duration =
        startsOn && endsOn
            ? moment
                  .duration(moment.duration(endsOn - startsOn, 'seconds').asHours(), 'hours')
                  .locale(locale)
                  .humanize()
            : undefined;
    const description = formatMessage(jamCardDescription);
    const index = `jam-${jamIndex}`;
    const props = {
        title,
        contentId,
        duration,
        description,
        index,
    };
    return (
        <div className='jam-card'>
            <CardContainer
                {...props}
                statusLabel={<JamStatusCardLabel status={eventStatus || fulfillmentStatus} />}
            >
                <Button iconName='external' target='_blank' href={eventURL} disabled={!eventURL}>
                    {formatMessage(jamCardFacilitateLabel)}
                </Button>
            </CardContainer>
        </div>
    );
};

export default JamCard;
