import { Modal, Toggle } from '@amzn/awsui-components-react-v3';
import { nanoid } from 'nanoid';
import { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useClassroomData } from '@/data/useClassroomData';
import { subscribeToTrainingPermissions, updateTrainingPermissions } from '@/modules/api';
import { useUserInfo } from '@/utils';

import messages from './LabToggle.messages';

const errorMessages = {
    'updateTrainingPermissions::403': messages.updateTrainingPermissions403Error,
    default: messages.noUpdateTrainingPermissions,
};

const trimAndLowercase = (s) => {
    if (typeof s === 'string') {
        return s.trim().toLocaleLowerCase();
    }
    return '';
};

const LabToggle = ({ classroomId, labArn }) => {
    const { formatMessage } = useIntl();
    const [toggleLabError, toggleLabErrorSet] = useState();
    const { classData, updateTrainingPermissions: localUpdateTrainingPermissions } =
        useClassroomData(classroomId, {
            withInstructors: true,
        });
    const { email = '' } = useUserInfo();
    const userIsClassroomInstructor =
        (classData?.classroom?.instructors || []).findIndex(
            (instructor) => instructor && trimAndLowercase(instructor) === trimAndLowercase(email),
        ) > -1;

    const onToggleChange = (e) => {
        updateTrainingPermissions({
            classroomId,
            arn: labArn,
            isActive: e.detail.checked,
            idempotencyToken: nanoid(),
        }).catch((error) => {
            const msg =
                errorMessages[`${error.path}::${error.statusCode}`] || errorMessages.default;
            toggleLabErrorSet(msg);
        });
    };

    const content = classData?.content || [];
    const jamTrainings = classData?.jamTrainings?.trainings || [];

    let lab;
    let labNumber;
    let deactivationMessage = messages.deactivateLab;
    let activationMessage = messages.activateLab;

    // The content could be a lab or jam
    const findByContentId = ({ contentId }) => labArn === contentId;
    const labIndex = content.findIndex(findByContentId);
    const jamIndex = jamTrainings.findIndex(findByContentId);
    if (labIndex > -1) {
        lab = content[labIndex];
        labNumber = labIndex + 1;
    } else if (jamIndex > -1) {
        lab = jamTrainings[jamIndex];
        labNumber = jamIndex + 1;
        deactivationMessage = messages.deactivateJam;
        activationMessage = messages.activateJam;
    }

    const isActive = lab?.isActive || false;

    useEffect(() => {
        subscribeToTrainingPermissions(localUpdateTrainingPermissions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Toggle
                className='lab-toggle'
                checked={isActive}
                onChange={onToggleChange}
                data-testid={`toggle-activation-${labNumber}`}
                controlId={`lab-card-toggle-${labNumber}`}
                ariaLabel={formatMessage(!isActive ? deactivationMessage : activationMessage, {
                    index: labNumber,
                })}
                disabled={!userIsClassroomInstructor}
            >
                {formatMessage(isActive ? messages.active : messages.inactive)}
            </Toggle>
            {toggleLabError ? (
                <Modal
                    visible
                    data-testid='toggle-training-modal'
                    header={formatMessage(messages.noUpdateTrainingHeader)}
                    onDismiss={() => toggleLabErrorSet(null)}
                    closeLabel={formatMessage(messages.closeModalButtonLabel)}
                >
                    {formatMessage(toggleLabError)}
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default LabToggle;
