import { Modal } from '@amzn/awsui-components-react-v3/polaris';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { ClassForm, Loader, PageWrapper, Breadcrumbs, GilmoreHealthBanner } from '@/components';
import { useClassroomData } from '@/data/useClassroomData';
import { updateClassroom, addInstructorsToClassroom } from '@/modules';
import {
    classDetailPageBreadcrumb,
    classEditPageBreadcrumb,
    classesPageBreadcrumb,
} from '@/utils/breadcrumbs';
import { isEmpty } from '@/utils/helpers';

import messages from './ClassEditPage.messages';

const errorMessages = {
    'updateClassroom::409': messages.updateClass409Error,
    default: messages.notUpdatedContent,
};

const ClassEditPage = () => {
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;

    const { formatMessage } = useIntl();
    const [updateError, updateErrorSet] = useState();
    const [scheduledEvents, scheduledEventsSet] = useState([]);
    const { classData } = useClassroomData(classroomId, {
        withInstructors: true,
    });
    const location = useLocation();
    const onSubmit = async (data) => {
        const payload = await updateClassroom(data);
        await addInstructorsToClassroom(
            data.instructors,
            payload.classroomId,
            data.classroom.providerArn,
        );
        return payload;
    };

    const handleError = (err) => {
        const msg = errorMessages[`${err.path}::${err.statusCode}`] || errorMessages.default;
        updateErrorSet(msg);
    };

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <GilmoreHealthBanner items={scheduledEvents} />
            <Breadcrumbs
                items={[
                    classesPageBreadcrumb(formatMessage),
                    classDetailPageBreadcrumb(formatMessage, classroomId),
                    classEditPageBreadcrumb(formatMessage, classroomId),
                ]}
            />
            <Loader isLoading={isEmpty(classData)}>
                {!isEmpty(classData) ? (
                    <ClassForm
                        data={classData?.classroom}
                        jamTrainings={classData?.jamTrainings?.trainings}
                        onSubmit={onSubmit}
                        classroomId={classroomId}
                        onError={handleError}
                        scheduledEventsSet={scheduledEventsSet}
                        scheduledEvents={scheduledEvents}
                        state={location?.state}
                    />
                ) : null}
            </Loader>
            {updateError ? (
                <Modal
                    visible
                    data-testid='class-edit-modal'
                    header={formatMessage(messages.notUpdatedHeader)}
                    onDismiss={() => updateErrorSet(null)}
                    closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
                >
                    {formatMessage(updateError)}
                </Modal>
            ) : null}
        </PageWrapper>
    );
};

export default ClassEditPage;
