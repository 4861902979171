import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { paths } from '@/utils';

import messages from './ErrorPage.messages';

const ErrorPage = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <div className='awsui-util-p-l awsui-util-t-c'>
                <h1 className='awsui-text-large'>{formatMessage(messages.pageHeading)}</h1>
                <h2>{formatMessage(messages.pageNotFound)}</h2>
                <p>{formatMessage(messages.pageAdvice)}</p>
                <Link to={paths.landingPage}>{formatMessage(messages.pageLink)}</Link>
            </div>
        </>
    );
};

export default ErrorPage;
