import { SideNavigation } from '@amzn/awsui-components-react-v3';
import { useHistory, useParams } from 'react-router-dom';

import { removeCourseNameFromLabTitle, paths } from '@/utils';

import './LabNavigation.scss';

const LabNavigation = ({ content, courseTitle }) => {
    const history = useHistory();
    const { classroomId, labNumber } = useParams();
    return (
        <SideNavigation
            data-testid='manage-labs__side-navigation'
            activeHref={paths.labPage(classroomId, labNumber)}
            items={content.map(({ title }, index) => ({
                type: 'link',
                text: removeCourseNameFromLabTitle(title, courseTitle),
                href: paths.labPage(classroomId, index + 1),
                index,
            }))}
            onFollow={(e) => {
                e.preventDefault();
                if (e.detail.index + 1 !== +labNumber) {
                    history.push(e.detail.href);
                }
            }}
        />
    );
};

export default LabNavigation;
