import _ from 'lodash';

import {
    getAttendanceStatusLabelMap,
    isSessionAttendanceModified,
} from './classAttendanceList.utils';

const PARTIALLY_ATTENDED_PERCENTAGE = 59;

export const getInitialState = ({ learningActivity }) => {
    return {
        selectedItems: [],
        editMode: false,
        learnersWithSessionAttendanceChanges: new Set(),
        attendanceRecordListReadOnly: [],
        attendanceRecordList: [],
        learningActivity,
        sorting: {
            sortingColumn: null,
            isDescending: true,
        },
        attendanceUpdateInProgress: false,
    };
};

export const reducer = (state, action) => {
    let updatedState = state;
    switch (action.type) {
        case 'markBulkAttendance': {
            updatedState = processBulkMarkAttendance(state, action);
            break;
        }
        case 'changeListMode':
            if (_.eq(action.event, 'EDIT')) {
                updatedState = {
                    ...state,
                    editMode: true,
                };
            } else {
                updatedState = {
                    ...state,
                    editMode: false,
                    attendanceRecordList: _.cloneDeep(state.attendanceRecordListReadOnly),
                    learnersWithSessionAttendanceChanges: new Set(),
                };
            }
            break;
        case 'markSingleAttendance': {
            updatedState = processMarkAttendance(state, action);
            break;
        }
        case 'onAttendanceDataLoaded': {
            const { stringBundle } = action.additionalData;
            const attendanceRecords = _.map(action.event, (rmsAttendanceRecord) => {
                const attendanceRecord = convertRMSAttendanceRecord(
                    rmsAttendanceRecord,
                    state.learningActivity.delivery_sessions,
                );
                updateSessionAttendanceForFiltering(attendanceRecord, stringBundle);
                return attendanceRecord;
            });

            updatedState = {
                ...state,
                attendanceRecordListReadOnly: attendanceRecords,
                attendanceRecordList: _.cloneDeep(attendanceRecords),
            };
            break;
        }
        case 'submitAttendance':
            updatedState = {
                ...state,
                attendanceUpdateInProgress: true,
            };
            break;

        case 'submitAttendanceSuccess':
            updatedState = {
                ...state,
                editMode: false,
                attendanceUpdateInProgress: false,
                attendanceRecordList: [],
                learnersWithSessionAttendanceChanges: new Set(),
            };
            break;

        case 'submitAttendanceError':
            updatedState = {
                ...state,
                attendanceUpdateInProgress: false,
            };
            break;
        case 'undoAttendanceChange':
            updatedState = resetAttendanceRecord(state, action);
            break;
        default:
        //nothing to do
    }
    return updatedState;
};

const resetAttendanceRecord = (state, action) => {
    let updatedState = state;
    const learnerId = action.event;
    const attendanceRecord = _.find(
        updatedState.attendanceRecordList,
        (record) => _.eq(record.learner.id, learnerId),
        0,
    );
    const unmodifiedAttendanceRecord = _.find(
        updatedState.attendanceRecordListReadOnly,
        (record) => _.eq(record.learner.id, learnerId),
        0,
    );
    _.forEach(attendanceRecord.deliverySessionAttendanceRecords, (sessionAttendance) => {
        const unmodifiedSessionAttendance = getOriginalSessionAttendance(
            unmodifiedAttendanceRecord,
            sessionAttendance.deliverySessionId,
        );
        sessionAttendance.attendanceStatus = unmodifiedSessionAttendance.attendanceStatus;
        sessionAttendance.attendanceDurationPercentage =
            unmodifiedSessionAttendance.attendanceDurationPercentage;
    });
    updatedState.learnersWithSessionAttendanceChanges.delete(learnerId);
    updatedState = {
        ...state,
    };
    return updatedState;
};

const processMarkAttendance = (state, action) => {
    let updatedState = state;
    const { learnerId, deliverySessionId, stringBundle } = action.additionalData;
    const attendanceRecord = _.find(
        updatedState.attendanceRecordList,
        (record) => _.eq(record.learner.id, learnerId),
        0,
    );
    const sessionAttendance = _.find(
        attendanceRecord.deliverySessionAttendanceRecords,
        (attendance) => _.eq(attendance.deliverySessionId, deliverySessionId),
        0,
    );
    const unmodifiedAttendanceRecord = _.find(
        updatedState.attendanceRecordListReadOnly,
        (record) => _.eq(record.learner.id, learnerId),
        0,
    );
    updateSessionAttendanceByStatus(
        sessionAttendance,
        action.event.detail.selectedOption.value,
        getOriginalSessionAttendance(unmodifiedAttendanceRecord, deliverySessionId),
    );
    updateSessionAttendanceForFiltering(attendanceRecord, stringBundle);
    updatedState = {
        ...state,
    };
    if (isAttendanceRecordModified(attendanceRecord, unmodifiedAttendanceRecord)) {
        updatedState.learnersWithSessionAttendanceChanges.add(learnerId);
    } else {
        updatedState.learnersWithSessionAttendanceChanges.delete(learnerId);
    }
    return updatedState;
};
const processBulkMarkAttendance = (state, action) => {
    let updatedState = state;
    const { deliverySessionId, attendanceStatus, selectedItems, stringBundle } =
        action.additionalData;
    const selectedLearnerIds = _.reduce(
        selectedItems,
        (resultSet, attendanceRecord) => {
            return resultSet.add(attendanceRecord.learner.id);
        },
        new Set(),
    );

    const unmodifiedAttendanceRecordsByUserId = _.reduce(
        state.attendanceRecordListReadOnly,
        (resultMap, attendanceRecordRO) => {
            if (selectedLearnerIds.has(attendanceRecordRO.learner.id)) {
                resultMap.set(attendanceRecordRO.learner.id, attendanceRecordRO);
            }
            return resultMap;
        },
        new Map(),
    );

    let hasUpdate = false;
    _.forEach(
        _.filter(updatedState.attendanceRecordList, (attendanceRecord) =>
            selectedLearnerIds.has(attendanceRecord.learner.id),
        ),
        (attendanceRecord) => {
            const learnerId = attendanceRecord.learner.id;
            let sessionAttendance = _.find(
                attendanceRecord.deliverySessionAttendanceRecords,
                (attendance) => _.eq(deliverySessionId, attendance.deliverySessionId),
                0,
            );
            let tempHasUpdate = false;
            if (!sessionAttendance) {
                tempHasUpdate = true;
                sessionAttendance = {
                    amsDeliverySession: {
                        id: deliverySessionId,
                    },
                };
                _.concat(attendanceRecord.deliverySessionAttendanceRecords, sessionAttendance);
            }
            if (!_.eq(sessionAttendance.attendanceStatus, attendanceStatus)) {
                const unmodifiedSessionAttendance = getOriginalSessionAttendance(
                    unmodifiedAttendanceRecordsByUserId.get(learnerId),
                    sessionAttendance.deliverySessionId,
                );
                updateSessionAttendanceByStatus(
                    sessionAttendance,
                    attendanceStatus,
                    unmodifiedSessionAttendance,
                );
                updateSessionAttendanceForFiltering(attendanceRecord, stringBundle);
                tempHasUpdate = true;
            }
            if (tempHasUpdate) {
                if (
                    isAttendanceRecordModified(
                        attendanceRecord,
                        unmodifiedAttendanceRecordsByUserId.get(attendanceRecord.learner.id),
                    )
                ) {
                    updatedState.learnersWithSessionAttendanceChanges.add(
                        attendanceRecord.learner.id,
                    );
                } else {
                    updatedState.learnersWithSessionAttendanceChanges.delete(
                        attendanceRecord.learner.id,
                    );
                }
            }
            hasUpdate = hasUpdate || tempHasUpdate;
        },
    );
    if (hasUpdate) {
        updatedState = {
            ...updatedState,
        };
    }
    return updatedState;
};

const getOriginalSessionAttendance = (originalAttendanceRecord, sessionId) => {
    return _.find(
        originalAttendanceRecord.deliverySessionAttendanceRecords,
        (item) => _.eq(item.deliverySessionId, sessionId),
        0,
    );
};

const isAttendanceRecordModified = (attendanceRecord, originalAttendanceRecord) => {
    for (const sessionAttendance of attendanceRecord.deliverySessionAttendanceRecords) {
        if (isSessionAttendanceModified(sessionAttendance, originalAttendanceRecord)) {
            return true;
        }
    }
    return false;
};

const attendanceStatusToDefaultPercentage = (status) => {
    switch (status) {
        case 'ATTENDED':
            return 100;
        case 'PARTIALLY_ATTENDED':
            return PARTIALLY_ATTENDED_PERCENTAGE;
        case 'NO_SHOW':
            return 0;
        case 'NOT_MARKED':
            return null;
        default:
            return undefined;
    }
};
const updateSessionAttendanceByStatus = (
    sessionAttendance,
    newStatus,
    unmodifiedSessionAttendance,
) => {
    let newAttendanceStatusPercentage = _.eq(
        newStatus,
        unmodifiedSessionAttendance.attendanceStatus,
    )
        ? unmodifiedSessionAttendance.attendanceDurationPercentage
        : attendanceStatusToDefaultPercentage(newStatus);
    sessionAttendance.attendanceStatus = newStatus;
    sessionAttendance.attendanceDurationPercentage = newAttendanceStatusPercentage;
};

const convertRMSAttendanceRecord = (attendanceRecord, deliverySessions) => {
    return {
        email: attendanceRecord.learner.emailAddress,
        lastName: attendanceRecord.learner.lastName,
        learner: {
            id: attendanceRecord.learner.id,
            email: attendanceRecord.learner.emailAddress,
            lastName: attendanceRecord.learner.lastName,
        },
        learningActivityAttendanceStatus:
            attendanceRecord.learningActivityAttendanceStatus ?? 'NOT_SET',
        deliverySessionAttendanceRecords: _.map(deliverySessions, (deliverySession) => {
            const deliverySessionAttendance = _.find(
                attendanceRecord.deliverySessionAttendanceRecords,
                (sessionAttendance) =>
                    _.eq(deliverySession.id, sessionAttendance.amsDeliverySession.id),
                0,
            );
            if (!deliverySessionAttendance) {
                return {
                    deliverySessionId: deliverySession.id,
                    attendanceStatus: 'NOT_MARKED',
                    attendanceDurationPercentage: undefined,
                };
            } else {
                return {
                    deliverySessionId: deliverySession.id,
                    attendanceStatus: deliverySessionAttendance.attendanceStatus,
                    attendanceDurationPercentage:
                        deliverySessionAttendance.attendanceDurationPercentage,
                };
            }
        }),
    };
};

const updateSessionAttendanceForFiltering = (attendanceRecord, stringBundle) => {
    const attendanceStatusLabels = getAttendanceStatusLabelMap(stringBundle);
    const sessionAttendanceStatusLabels = _.reduce(
        attendanceRecord.deliverySessionAttendanceRecords,
        (result, sessionAttendance) => {
            result.push(attendanceStatusLabels[sessionAttendance.attendanceStatus]);
            return result;
        },
        [],
    );
    attendanceRecord.statusLabels = _.join(sessionAttendanceStatusLabels);
};

export const getEventHandlers = (dispatch, notificationStateUpdater) => {
    const dispatchEvent = (eventType, event, optionalAdditionalData) => {
        dispatch({
            type: eventType,
            event,
            additionalData: optionalAdditionalData,
        });
    };
    const dispatchNotification = (type, header, content) => {
        notificationStateUpdater([
            {
                header,
                content,
                type,
                dismissible: true,
                dismissLabel: 'dismiss',
                onDismiss: () => notificationStateUpdater([]),
            },
        ]);
    };

    return {
        onAttendanceRecordLoaded: (newAttendanceData, stringBundle) =>
            dispatchEvent('onAttendanceDataLoaded', newAttendanceData, { stringBundle }),
        onBulkActionClicked: (
            event,
            { deliverySessionId, attendanceStatus },
            selectedItems,
            stringBundle,
        ) =>
            dispatchEvent('markBulkAttendance', event, {
                deliverySessionId,
                attendanceStatus,
                selectedItems,
                stringBundle,
            }),
        onSingleSessionAttendanceChange: (event, learnerId, deliverySessionId, stringBundle) =>
            dispatchEvent('markSingleAttendance', event, {
                learnerId,
                deliverySessionId,
                stringBundle,
            }),
        onSubmitAttendanceChange: () => dispatchEvent('submitAttendance'),
        onSubmitAttendanceChangeSuccess: () => dispatchEvent('submitAttendanceSuccess'),
        onSubmitAttendanceChangeError: () => dispatchEvent('submitAttendanceError'),
        onUndoAttendanceRecordChange: (learnerId) =>
            dispatchEvent('undoAttendanceChange', learnerId),
        onListModeChangeToEdit: () => dispatchEvent('changeListMode', 'EDIT'),
        onListModeChangeToView: () => dispatchEvent('changeListMode', 'VIEW'),
        onError: (header, content) => dispatchNotification('error', header, content),
        onSuccess: (header, content) => dispatchNotification('info', header, content),
    };
};
