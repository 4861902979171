import { Button, Modal } from '@amzn/awsui-components-react';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { messages } from '@/components/classForm/ClassForm.messages';
import { paths } from '@/utils';

// Need both component properties because a user could remain on the page without any state updates
// where a class would transition from being active to expired and the initiallyExpired value
// wouldn't change. This means the user could then submit the form and get the error from
// the backend. Both properties ensure we will continue to show the expired error message
const ExpiredClassModal = ({ initiallyExpired, expiredFromBackend }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [isVisible, isVisibleSet] = useState(initiallyExpired);

    const clear = () => isVisibleSet(false);

    useEffect(() => {
        if (expiredFromBackend) {
            isVisibleSet(expiredFromBackend);
        }
    }, [expiredFromBackend]);

    return (
        <Modal
            data-testid={`expired-modal-${isVisible ? 'visible' : 'hidden'}`}
            visible={isVisible}
            onDismiss={clear}
            closeLabel={formatMessage(messages.closeModalButtonLabel)}
            header={formatMessage(messages.expiredClassHeader)}
            footer={
                <div className='expired-modal__footer'>
                    <span className='awsui-util-f-r'>
                        <Button
                            onClick={() => history.push(paths.classPage('new'))}
                            variant='primary'
                        >
                            {formatMessage(messages.createClassButtonText)}
                        </Button>
                    </span>
                    <span className='awsui-util-f-r'>
                        <Button onClick={clear} variant='normal' className='awsui-util-mr-s'>
                            <span data-testid='expired-modal__cancel-btn'>
                                {formatMessage(messages.cancelButtonText)}
                            </span>
                        </Button>
                    </span>
                </div>
            }
        >
            {formatMessage(messages.expiredClassBody)}
        </Modal>
    );
};

export default ExpiredClassModal;
