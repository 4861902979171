import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Switch, Route } from 'react-router-dom';

import { Authenticator, Header, Footer, ErrorFallback, Loader } from '@/components';
import UserKeyDisplay from '@/components/userKeyDisplay/UserKeyDisplay';
import {
    ClassPage,
    ClassDetailPage,
    ClassEditPage,
    ClassCreatePage,
    ErrorPage,
    LabPage,
    ProvidersPage,
    ClassListPageV2,
    AttendancePage,
    ResourceConsolidationPage,
} from '@/containers';
import { GilmoreHealthProvider } from '@/data/GilmoreHealthContext';
import { ProviderCtxProvider } from '@/data/ProviderContext';
import { useFlags } from '@/utils/flags';
import { paths, parameters, redirect } from '@/utils/paths';
import { QueryClientProviderWrapper } from '@/utils/QueryClientProvider';
import { checkResourceConsolidation } from '@/utils/resetResources';
import { UserInfoProvider } from '@/utils/userInfo';

import './App.css';

const { classroomId } = parameters;

const App = ({ globals = window }) => {
    const features = useFlags();
    const [consolidated, setConsolidated] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [consolidationChecked, setConsolidationChecked] = useState(false);

    useEffect(() => {
        checkResourceConsolidation(features.requiresReset).then((result) => {
            setConsolidated(result.hasResourcesConsolidated);
            setRedirecting(result.isRedirecting);
            setConsolidationChecked(true);
        });
    }, [consolidated, features.requiresReset]);

    return (
        <div className='awsui'>
            <Authenticator globals={globals}>
                <QueryClientProviderWrapper>
                    <ProviderCtxProvider globals={globals}>
                        <UserInfoProvider>
                            <GilmoreHealthProvider>
                                <Header />
                                <main className='app-body'>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {features.requiresReset &&
                                        (!consolidationChecked || redirecting) ? (
                                            <Loader isLoading={true}></Loader>
                                        ) : (
                                            <Switch>
                                                <Route
                                                    path='/callback'
                                                    exact
                                                    render={() => (
                                                        <Redirect
                                                            to={redirect(globals, features)}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path='/'
                                                    exact
                                                    render={() => (
                                                        <Redirect
                                                            to={redirect(globals, features)}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    strict
                                                    path='(.*//+.*)'
                                                    render={({ location }) => (
                                                        <Redirect
                                                            to={location.pathname.replace(
                                                                /\/\/+/g,
                                                                '/',
                                                            )}
                                                        />
                                                    )}
                                                />
                                                {!consolidated && (
                                                    <Route
                                                        path='*'
                                                        component={ResourceConsolidationPage}
                                                    />
                                                )}
                                                <Route
                                                    exact
                                                    path={paths.providersPage}
                                                    component={ProvidersPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classListPage}
                                                    component={ClassListPageV2}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classPage('new')}
                                                    component={ClassCreatePage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classPage(classroomId)}
                                                    component={ClassPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classDetailPage(
                                                        parameters.classroomId,
                                                    )}
                                                    component={ClassDetailPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classEditPage(
                                                        parameters.classroomId,
                                                    )}
                                                    component={ClassEditPage}
                                                />

                                                {features.attendanceMarking && (
                                                    <Route
                                                        exact
                                                        path={paths.classAttendance(
                                                            parameters.classroomId,
                                                        )}
                                                        component={AttendancePage}
                                                    />
                                                )}

                                                <Route
                                                    path={paths.labPage(
                                                        parameters.classroomId,
                                                        parameters.labNumber,
                                                    )}
                                                    component={LabPage}
                                                />
                                                <Route component={ErrorPage} />
                                            </Switch>
                                        )}
                                    </ErrorBoundary>
                                </main>
                            </GilmoreHealthProvider>
                            <UserKeyDisplay />
                            <Footer />
                        </UserInfoProvider>
                    </ProviderCtxProvider>
                </QueryClientProviderWrapper>
            </Authenticator>
        </div>
    );
};

export default App;
