import { Header, ColumnLayout, Link, Box, FormField, Input } from '@amzn/awsui-components-react-v3';
import { toNumber, isEmpty, trim, toString, isNaN, reduce } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getErrorPropertyKey } from '@/utils/gonzo-error-parse-utils';

import { messages } from '../ClassForm.messages';
import { MAX_CLASS_CAPACITY, MIN_CLASS_CAPACITY } from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

const SubProviderList = ({
    subProviders = [],
    setClassData,
    classCapacity,
    capacityBySubProvider = {},
    setOpenCapacityInfoModal,
    fieldsInvalid = {},
    providerAvailableLicensesMap,
    isLoadingAvailableLicenses,
    isATPAndMultiGilmorePhase2Enabled,
}) => {
    const { formatMessage } = useIntl();
    const subProviderCapacityChangeHandler = useMemo(
        () => (updatedSubProviderArn, newSubProviderCapacity) => {
            setClassData((prevClassroomState) => {
                const newClassroomState = calculateClassroomState(
                    prevClassroomState,
                    updatedSubProviderArn,
                    newSubProviderCapacity,
                );
                return newClassroomState;
            });
        },
        [setClassData],
    );

    const providerRowElements = [];
    providerRowElements.push(
        <Header variant='h5' key={'providerNameHeader'}>
            {formatMessage(messages.capacityMultiProviderLabel)}
        </Header>,
    );
    providerRowElements.push(
        <Header variant='h5' key={'numStudentInputHeader'}>
            {formatMessage(messages.numberOfStudentsLabel)}
        </Header>,
    );
    if (isATPAndMultiGilmorePhase2Enabled)
        providerRowElements.push(
            <Header variant='h5' key={'availableLicencesHeader'}>
                {formatMessage(messages.availableLicensesLabel)}
            </Header>,
        );

    for (let i = 0; i < subProviders.length; i++) {
        const regionProvider = subProviders[i];
        providerRowElements.push(
            <div key={`providerName_${i}`}>{regionProvider.providerName}</div>,
        );
        providerRowElements.push(
            <FormField
                stretch={false}
                key={`ff_provider${i}`}
                label={formatMessage(messages.numberOfStudentSubProviderInputLabel, {
                    subProviderName: regionProvider.providerName,
                })}
                errorText={showErrorMessage(formatMessage, {
                    fieldsInvalid,
                    property: getErrorPropertyKey('classCapacity', regionProvider.providerArn),
                })}
            >
                <Input
                    id={`subProviderInput[${i}]`}
                    value={
                        capacityBySubProvider[regionProvider.providerArn]
                            ? capacityBySubProvider[regionProvider.providerArn].capacity
                            : '0'
                    }
                    onChange={(e) => {
                        subProviderCapacityChangeHandler(
                            regionProvider.providerArn,
                            e.detail.value,
                        );
                    }}
                    type='number'
                    invalid={
                        !!fieldsInvalid[
                            getErrorPropertyKey('classCapacity', regionProvider.providerArn)
                        ]
                    }
                />
            </FormField>,
        );
        if (isATPAndMultiGilmorePhase2Enabled)
            providerRowElements.push(
                isLoadingAvailableLicenses ? (
                    <div>{formatMessage(messages.loadingField)}</div>
                ) : (
                    <div>
                        {providerAvailableLicensesMap
                            ? providerAvailableLicensesMap[regionProvider.providerArn]
                            : '-'}
                    </div>
                ),
            );
    }
    providerRowElements.push(
        <Header variant='h4' key={'providerNumberTotalHeader'}>
            {formatMessage(messages.numberOfStudentParentProviderTotal)}
        </Header>,
    );
    providerRowElements.push(
        <FormField
            stretch={false}
            key='ff_numStudentTotal'
            label={formatMessage(messages.numberOfStudentParentProviderTotalInputLabel)}
            errorText={showErrorMessage(formatMessage, {
                fieldsInvalid,
                property: 'classCapacity',
            })}
        >
            <Input
                value={classCapacity}
                disabled={true}
                invalid={!!fieldsInvalid['classCapacity']}
            />
        </FormField>,
    );

    return (
        <ColumnLayout disableGutters='true' key={'0'}>
            <Box margin={{ top: 'm', bottom: 's' }}>
                <Header
                    variant='h4'
                    description={formatMessage(messages.numberOfStudentsHint, {
                        max: MAX_CLASS_CAPACITY,
                        min: MIN_CLASS_CAPACITY,
                    })}
                    info={
                        <Link
                            variant='info'
                            onFollow={() => setOpenCapacityInfoModal(true)}
                            ariaLabel={formatMessage(messages.infoButtonOpenLabel)}
                        >
                            {formatMessage(messages.infoButtonText)}
                        </Link>
                    }
                >
                    {formatMessage(messages.numberOfStudentsLabel)}
                </Header>
            </Box>
            <ColumnLayout
                columns={isATPAndMultiGilmorePhase2Enabled ? 3 : 2}
                borders='horizontal'
                key='providerRows'
            >
                {providerRowElements}
            </ColumnLayout>
        </ColumnLayout>
    );
};

//Exported for testing
export const calculateClassroomState = (
    prevClassroomState,
    updatedSubProviderArn,
    newSubProviderCapacity,
) => {
    const newClassroomState = {
        ...prevClassroomState,
    };

    let originalUpdatedRegionCapacity = 0;

    if (prevClassroomState.capacityBySubProvider[updatedSubProviderArn]) {
        originalUpdatedRegionCapacity = toNumber(
            prevClassroomState.capacityBySubProvider[updatedSubProviderArn].capacity,
        );
    }

    if (isEmpty(trim(newSubProviderCapacity))) {
        newClassroomState.capacityBySubProvider[updatedSubProviderArn] = {
            capacity: toString(originalUpdatedRegionCapacity),
        };
    } else {
        let validatedNewRegionCapacity = isNaN(toNumber(newSubProviderCapacity))
            ? originalUpdatedRegionCapacity
            : toNumber(newSubProviderCapacity);
        if (validatedNewRegionCapacity < 0) {
            validatedNewRegionCapacity = originalUpdatedRegionCapacity;
        }
        newClassroomState.capacityBySubProvider[updatedSubProviderArn] = {
            capacity: toString(validatedNewRegionCapacity),
        };
    }

    const newClassCapacity = reduce(
        newClassroomState.capacityBySubProvider,
        (sum, value) => sum + toNumber(value.capacity),
        0,
    );
    newClassroomState.classCapacity = toString(newClassCapacity);

    return newClassroomState;
};

export default SubProviderList;
