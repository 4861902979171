import { Box } from '@amzn/awsui-components-react-v3';

import { NavigationDropdown } from '@/components';

import './SectionHeader.scss';

/**
 *
 * @param {actions} React.Component Extra components to render on the right side that would be like buttons
 * @returns
 */
const SectionHeader = ({ title, variant = 'h2', tagOverride, actions, suppressNav = false }) => {
    return (
        <Box data-testid='section-header' padding={{ vertical: 's' }}>
            <Box variant={variant} data-testid='section-subheader' tagOverride={tagOverride}>
                {title}
            </Box>
            {!suppressNav && (
                <div>
                    {actions}
                    <NavigationDropdown />
                </div>
            )}
        </Box>
    );
};

export default SectionHeader;
