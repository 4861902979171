import { get as _get } from 'lodash';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { HeroBanner, Loader, LabList, Breadcrumbs } from '@/components';
import { useClassroomData } from '@/data/useClassroomData';
import { classPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';
import { isEmpty } from '@/utils/helpers';

import messages from './ClassPage.messages';

import './ClassPage.css';

const ClassPage = () => {
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;

    const { formatMessage } = useIntl();
    const { classData } = useClassroomData(classroomId);
    const classAbstract = _get(classData, 'course.abstract');

    return (
        <Fragment>
            <Helmet>
                <title>{_get(classData, 'course.title')}</title>
            </Helmet>
            <Loader message={formatMessage(messages.loading)} isLoading={isEmpty(classData)}>
                <div className='class-page'>
                    <HeroBanner
                        title={_get(classData, 'course.title')}
                        locationType={_get(classData, 'classroom.locationData.locationType')}
                    />
                    <div className='class-page__body'>
                        <Breadcrumbs
                            items={[
                                classesPageBreadcrumb(formatMessage),
                                classPageBreadcrumb(formatMessage, classroomId),
                            ]}
                        />
                        {classAbstract ? (
                            <Fragment>
                                <h2 className='content-header' data-testid='description-header'>
                                    {formatMessage(messages.header)}
                                </h2>
                                <p data-testid='abstract'>{classAbstract}</p>
                            </Fragment>
                        ) : null}
                        <section>
                            <LabList />
                        </section>
                    </div>
                </div>
            </Loader>
        </Fragment>
    );
};

export default ClassPage;
