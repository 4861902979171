import './PageWrapper.css';

const PageWrapper = ({ children, contentLayoutType }) => {
    if (contentLayoutType === 'wide') {
        return <div className='page-wrapper-wide'>{children}</div>;
    } else {
        return <div className='page-wrapper'>{children}</div>;
    }
};

export default PageWrapper;
