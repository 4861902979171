import {
    FormField,
    Input,
    Select,
    Header,
    Container,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { get as _get } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';

import { ClassroomContext } from '@/data/ClassroomContext';
import { useProvider } from '@/data/ProviderContext';
import {
    getAllCourseVersions,
    getCourseVersionDetails,
    listAuthorizedCourses,
    listMajorVersions,
} from '@/modules';

import { messages } from '../ClassForm.messages';
import {
    mapCoursesToSelectObject,
    mapCoursesVersionsToSelectObject,
    mapLocalesToSelectObject,
    usePrevious,
    handleFormValueChange,
} from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import '../ClassForm.css';

const CourseInformation = ({
    isNewClass,
    fieldsInvalid,
    courseId,
    courseVersionId,
    langLocale,
    setClassData,
    initialCourseName,
    setJamInformation,
}) => {
    const { formatMessage } = useIntl();
    const { classData: existingClassroomData } = useContext(ClassroomContext);
    const initialCourseList = isNewClass
        ? []
        : [{ courseId, title: existingClassroomData?.course?.title }];
    const [courses, setCourses] = useState(initialCourseList);
    const [courseVersions, setCourseVersions] = useState([]);
    const [courseVersionsLoading, setCourseVersionsLoading] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState({});
    const [courseVersionDetailsLoading, courseVersionDetailsLoadingSet] = useState(false);
    const [authorizedCourses, authorizedCoursesSet] = useState([]);
    const [courseName, courseNameSet] = useState(initialCourseName);
    const providerArn = useProvider()?.arn;

    // usePrevious helps to determine if there was a change between actual
    // values for courseId and courseVersionId which inform whether to reset
    // the courseVersionId and langLocale inputs respectively. This matters
    // when a form is updated with data returned from an existing class because
    // it is responding to changes and would clear out any current choice when
    // populating the form state with the provided data
    const prevCourseId = usePrevious(courseId);
    const prevCourseVersionId = usePrevious(courseVersionId);

    // get courses data
    useEffect(() => {
        if (isNewClass) {
            listAuthorizedCourses(providerArn).then(authorizedCoursesSet);
        }
    }, [providerArn, isNewClass]);

    useEffect(() => {
        if (courseName) {
            listMajorVersions(courseName, providerArn).then((r) =>
                setCourses(
                    r.map(({ collectionArn, collectionTitle: title }) => ({
                        courseId: collectionArn,
                        title,
                    })),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseName]);

    // reset any selected course version id when course id changes and get
    // corresponding course versions
    useEffect(() => {
        if (prevCourseId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseVersionId',
            });
            courseVersionDetailsSet({});
        }
        if (!courseId) return;
        setCourseVersionsLoading(true);
        getAllCourseVersions(courseId, providerArn)
            .then(setCourseVersions)
            .finally(() => setCourseVersionsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    // reset any selected langLocale when course version id changes and get
    // corresponding locales
    useEffect(() => {
        if (prevCourseVersionId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'langLocale',
            });
        }
        if (!courseVersionId) return;
        courseVersionDetailsLoadingSet(true);
        getCourseVersionDetails(courseVersionId, providerArn)
            .then(courseVersionDetailsSet)
            .finally(() => courseVersionDetailsLoadingSet(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseVersionId]);

    useEffect(() => {
        if (typeof setJamInformation === 'function') {
            setJamInformation({
                challengeSetID: courseVersionDetails?.jamConfig?.challengeSetID,
                contentTypes: courseVersionDetails?.contentTypes,
            });
        }
    }, [courseVersionDetails, setJamInformation]);

    const courseOptions = authorizedCourses.map(({ id, title }) => ({
        value: id,
        label: title,
    }));

    const templateOptions = courses
        .map(mapCoursesToSelectObject)
        .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : +1));

    const versionOptions = courseVersions.map(mapCoursesVersionsToSelectObject(formatMessage));
    const localeOptions = _get(courseVersionDetails, 'supportedLocales', []).map(
        mapLocalesToSelectObject,
    );

    return (
        <Container
            data-testid='course-information'
            header={<Header variant='h2'>{formatMessage(messages.courseInfoHeader)}</Header>}
        >
            <SpaceBetween direction='vertical' size='m'>
                {isNewClass && (
                    <FormField stretch label={formatMessage(messages.coursesLabel)}>
                        <Select
                            ariaRequired
                            data-testid='course-information-courses'
                            disabled={!isNewClass}
                            selectedOption={courseOptions.find(({ value }) => value === courseName)}
                            ariaLabel={formatMessage(messages.coursesPlaceholder)}
                            filteringType='auto'
                            filteringPlaceholder={formatMessage(
                                messages.filterCourseTemplatePlaceholder,
                            )}
                            placeholder={formatMessage(messages.coursesPlaceholder)}
                            empty={formatMessage(messages.courseEmptyLabel)}
                            options={courseOptions}
                            onChange={(e) => courseNameSet(e.detail.selectedOption.value)}
                        />
                    </FormField>
                )}
                <FormField
                    stretch
                    label={formatMessage(messages.courseTemplateLabel)}
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'courseId',
                    })}
                >
                    <Select
                        ariaRequired
                        data-testid='course-information-course-ids'
                        invalid={!!fieldsInvalid.courseId}
                        disabled={!isNewClass}
                        selectedOption={templateOptions.find(({ value }) => value === courseId)}
                        ariaLabel={formatMessage(messages.courseTemplatePlaceholder)}
                        options={templateOptions}
                        empty={formatMessage(messages.courseTemplateEmptyLabel)}
                        placeholder={formatMessage(messages.courseTemplatePlaceholder)}
                        selectedLabel={formatMessage(messages.selectedLabel)}
                        onChange={(e) => {
                            handleFormValueChange({
                                value: e.detail.selectedOption.value,
                                setData: setClassData,
                                keyPath: 'courseId',
                            });
                        }}
                    ></Select>
                </FormField>
                <FormField
                    stretch
                    label={formatMessage(messages.courseVersionLabel)}
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'courseVersionId',
                    })}
                >
                    <Select
                        ariaRequired
                        data-testid='course-information-course-versions'
                        invalid={!!fieldsInvalid.courseVersionId}
                        selectedOption={versionOptions.find(
                            ({ value }) => value === courseVersionId,
                        )}
                        ariaLabel={formatMessage(messages.courseVersionPlaceholder)}
                        options={versionOptions}
                        empty={formatMessage(
                            courseVersionsLoading
                                ? messages.loadingField
                                : messages.courseVersionEmptyLabel,
                        )}
                        placeholder={formatMessage(messages.courseVersionPlaceholder)}
                        selectedLabel={formatMessage(messages.selectedLabel)}
                        onChange={(e) =>
                            handleFormValueChange({
                                value: e.detail.selectedOption.value,
                                setData: setClassData,
                                keyPath: 'courseVersionId',
                            })
                        }
                        filteringType='auto'
                        filteringPlaceholder={formatMessage(
                            messages.filterCourseVersionPlaceholder,
                        )}
                        filteringLabel={formatMessage(messages.filterCourseVersionPlaceholder)}
                    ></Select>
                </FormField>
                <FormField stretch label={formatMessage(messages.courseVersionContentLabel)}>
                    <Input
                        data-testid='course-information-content-version'
                        readonly
                        disabled
                        value={_get(courseVersionDetails, 'contentVersion', '')}
                    />
                </FormField>
                <FormField
                    stretch
                    label={formatMessage(messages.courseLanguageLabel)}
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'langLocale',
                    })}
                >
                    <Select
                        ariaRequired
                        data-testid='course-information-lang-locales'
                        invalid={!!fieldsInvalid.langLocale}
                        selectedOption={localeOptions.find(({ value }) => value === langLocale)}
                        ariaLabel={formatMessage(messages.courseLanguagePlaceholder)}
                        options={localeOptions}
                        empty={formatMessage(
                            courseVersionDetailsLoading
                                ? messages.loadingField
                                : messages.courseLanguageEmptyLabel,
                        )}
                        placeholder={formatMessage(messages.courseLanguagePlaceholder)}
                        selectedLabel={formatMessage(messages.selectedLabel)}
                        onChange={(e) =>
                            handleFormValueChange({
                                value: e.detail.selectedOption.value,
                                setData: setClassData,
                                keyPath: 'langLocale',
                            })
                        }
                    ></Select>
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default CourseInformation;
