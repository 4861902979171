import { FormField, Input, Container, Button, Header } from '@amzn/awsui-components-react-v3';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { aggregatePropertyErrors } from '@/components/classForm/ClassForm.utils';
import { showErrorMessage } from '@/components/classForm/FieldErrors';
import { ProviderType } from '@/containers/providerPages/ProvidersPage';
import { useUserInfo } from '@/utils/userInfo';

import { messages } from '../classForm/ClassForm.messages';

import './InstructorAssign.css';

/**
 * Contains info about which form fields have invalid data
 * @typedef {Object} InstructorAssignFieldsInvalid
 * @property {boolean} instructors
 */

/**
 * Component for managing classroom instructors
 * @param {Object} props
 * @param {boolean} props.isNewClass
 * @param {boolean} props.isGrimsbyClass
 * @param {string} props.providerName
 * @param {("DIRECT"|"ATP")} props.providerType
 * @param {string[]} props.instructors
 * @param {(instructors: string[]) => undefined} props.instructorsSet
 * @param {InstructorAssignFieldsInvalid} props.fieldsInvalid {@link InstructorAssignFieldsInvalid}
 * @returns
 */
const InstructorAssign = ({
    isNewClass,
    isGrimsbyClass,
    providerName,
    providerType,
    instructors,
    instructorsSet,
    fieldsInvalid,
}) => {
    const container = useRef(null);
    const { userIsTrainingCoordinator } = useUserInfo();
    const { formatMessage } = useIntl();
    const instructorWidgetProps = {
        isNewClass,
        isGrimsbyClass,
        instructors,
        fieldsInvalid,
        add() {
            if (instructors.length < 5) {
                instructorsSet(instructors.concat(['']));
                // automatically set the focus to the newly added input element
                container.current
                    .querySelector(
                        `[data-testid="ClassForm__instructor-${instructors.length}"] input[type="text"]`,
                    )
                    .focus();
            }
        },
        remove(index) {
            if (index !== 0 || userIsTrainingCoordinator) {
                // instructors cannot delete themselves from a class
                const copy = [...instructors];
                copy.splice(index, 1);
                instructorsSet(copy);
            }
        },
        update(index, value) {
            if (index !== 0 || userIsTrainingCoordinator) {
                // instructors cannot edit themselves
                const copy = [...instructors];
                copy.splice(index, 1, value);
                instructorsSet(copy);
            }
        },
    };

    return (
        <Container
            id='class-provider'
            header={
                <Header>
                    {providerType === ProviderType.Direct
                        ? formatMessage(messages.classProviderHeader)
                        : formatMessage(messages.classPartnerHeader)}
                </Header>
            }
        >
            <div ref={container}>
                <FormField
                    stretch
                    label={
                        providerType === ProviderType.Direct
                            ? formatMessage(messages.trainingProviderLabel)
                            : formatMessage(messages.trainingPartnerLabel)
                    }
                >
                    {/* Defaults to provider of class and can't be changed */}
                    <Input value={providerName} readOnly />
                </FormField>
                <MultipleInstructorsWidget {...instructorWidgetProps} />
            </div>
        </Container>
    );
};

/**
 * Component that allows adding/removing instructors for a classroom
 * @param {Object} props
 * @param {boolean} props.isNewClass
 * @param {boolean} props.isGrimsbyClass
 * @param {string[]} props.instructors
 * @param {InstructorAssignFieldsInvalid} props.fieldsInvalid {@link InstructorAssignFieldsInvalid}
 * @param {(instructorIndex: number) => undefined} props.remove handler to remove an instructor input field
 * @param {() => undefined} props.add handler to add an instructor input field
 * @param {(number, Input.ChangeDetail.value) => undefined} props.update handler to update an instructor email
 * @returns
 */
const MultipleInstructorsWidget = ({
    isNewClass,
    isGrimsbyClass,
    instructors,
    fieldsInvalid,
    remove,
    add,
    update,
}) => {
    const { formatMessage } = useIntl();
    const { userIsTrainingCoordinator } = useUserInfo();

    const disabledForGrimsbyClass = !isNewClass && isGrimsbyClass;

    return (
        <fieldset className='instructor-assign-fieldset'>
            <legend>{formatMessage(messages.instructorLabel)}</legend>
            <div className='awsui-grid instructor-fields-wrapper'>
                {instructors.map((value, i) => {
                    const blockFirstFieldEdit = !userIsTrainingCoordinator && i === 0;
                    const removeButtonDisable =
                        blockFirstFieldEdit ||
                        (instructors.length === 1 && value === '') ||
                        disabledForGrimsbyClass;
                    const id = `ClassForm__instructor-${i}`;
                    const aggregatedErrors = aggregatePropertyErrors(
                        value,
                        fieldsInvalid.instructors,
                    );
                    return (
                        <div className='awsui-row' key={id}>
                            <FormField
                                className='col-xxxs-12 col-xxs-9 col-xs-9 col-s-9'
                                stretch
                                errorText={showErrorMessage(formatMessage, {
                                    fieldsInvalid: { instructors: aggregatedErrors || [] },
                                    property: 'instructors',
                                })}
                            >
                                <Input
                                    ariaRequired
                                    disableBrowserAutocorrect
                                    inputMode='email'
                                    placeholder={formatMessage(messages.instructorInputPlaceholder)}
                                    value={value}
                                    invalid={!!aggregatedErrors}
                                    data-testid={id}
                                    disabled={blockFirstFieldEdit || disabledForGrimsbyClass}
                                    onChange={(e) => update(i, e.detail.value)}
                                    ariaLabel={formatMessage(messages.instructorInputLabel, {
                                        index: i + 1,
                                    })}
                                />
                            </FormField>
                            <FormField className='col-xxxs-12 col-xxs-3 col-xs-3 col-s-3'>
                                <Button onClick={() => remove(i)} disabled={removeButtonDisable}>
                                    {formatMessage(messages.removeButtonText)}
                                </Button>
                            </FormField>
                        </div>
                    );
                })}
            </div>
            <FormField>
                <Button
                    onClick={add}
                    disabled={instructors.length >= 5 || disabledForGrimsbyClass}
                    style={{ marginTop: '2rem' }}
                >
                    {formatMessage(messages.addInstructorButtonText)}
                </Button>
            </FormField>
            <small>{formatMessage(messages.maximumInstructorNote)}</small>
        </fieldset>
    );
};

export default InstructorAssign;
