import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { signInWithCurrentProvider } from '@/modules';
import { usePrepareClient } from '@/modules/supergraph/client';

export const RoleType = {
    SERVICE: 'SERVICE',
    SUPER_USER: 'SUPER_USER',
    INTERNAL_EMPLOYEE: 'INTERNAL_EMPLOYEE',
    LEARNING_ADMIN: 'LEARNING_ADMIN',
    POST_AUTH_PRE_VIAS: 'POST_AUTH_PRE_VIAS',
    LEARNING_ACCOUNT_IT_CONTACT: 'LEARNING_ACCOUNT_IT_CONTACT',
    LEARNER: 'LEARNER',
};

export const UserStatus = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    DEACTIVATED: 'DEACTIVATED',
};

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            id
            status
            userRole
        }
    }
`;

export const useGetCurrentUserDelayed = () => {
    const ready = usePrepareClient();
    const [getUser, { called, error }] = useLazyQuery(GET_CURRENT_USER, {
        variables: {},
    });

    useEffect(() => {
        (async function () {
            if (error?.graphQLErrors[0]?.message?.includes('EmailAddress is not verified')) {
                await signInWithCurrentProvider();
            }
        })();
    }, [error]);

    const getCurrentUserWrapper = useMemo(() => {
        return () => {
            if (ready) {
                return getUser();
            }
        };
    }, [ready, getUser]);

    return {
        getCurrentUser: getCurrentUserWrapper,
        called,
    };
};
