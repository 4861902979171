import { AppLayout } from '@amzn/awsui-components-react';
import { Alert, Box, Button, Modal } from '@amzn/awsui-components-react-v3';
import { get as _get } from 'lodash';
import { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { LabNavigation, IngressTable, HeroBanner, SectionHeader, Breadcrumbs } from '@/components';
import { CLASS_ACCESS_TYPES } from '@/components/classForm/ClassForm.utils';
import { useClassroomData } from '@/data/useClassroomData';
import { getStudentTrainingsByClassroomId } from '@/graphql/queries';
import { executeRequest } from '@/modules/api';
import { removeCourseNameFromLabTitle } from '@/utils';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';
import { useFlags } from '@/utils/flags';

import { hiddenAlertBannerItem } from '../../components/ingressTable/IngressTable.utils';

import messages from './Lab.messages';

const { labTitle, somethingWentWrongTrainings, close } = messages;

const errorMessages = {
    'getStudentTrainingsByClassroomId::403': messages.getStudentTrainingsByClassroomId403Error,
    default: messages.cannotGetStudentLabs,
};

const A_MINUTE = 60000;

const useQueryOptions = {
    refetchOnWindowFocus: false,
    refetchInterval: A_MINUTE,
};

const Lab = () => {
    const params = useParams();
    const { classroomId: encodedClassroomId } = params;
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const labNumber = parseInt(params.labNumber);
    const { formatMessage } = useIntl();
    const { studentRoster } = useFlags();
    const translatedLabTitle = formatMessage(labTitle);
    const { classData } = useClassroomData(classroomId);
    const { content, classroom } = classData;
    const currentLab = _get(content, `[${labNumber - 1}]`, {});
    const labId = currentLab.contentId;
    const [getTrainingsError, getTrainingsErrorSet] = useState(false);
    const [alertBannerItem, alertBannerItemSet] = useState(hiddenAlertBannerItem);
    // banner to inform instructor of resetting invalid region
    const [regionBannerItem, regionBannerItemSet] = useState(hiddenAlertBannerItem);
    // banner to inform instructor cannot preload lab for students who exceed active lab limit.
    const [warningBannerItem, warningBannerItemSet] = useState(hiddenAlertBannerItem);
    const hasStudentRoster =
        studentRoster && classData?.classroom?.accessType === CLASS_ACCESS_TYPES.roster;

    const queryParams = {
        classroomId,
        accessType: classData?.classroom?.accessType,
    };
    if (hasStudentRoster) {
        queryParams.accessType = CLASS_ACCESS_TYPES.roster;
    }

    const { data, isLoading, refetch, error } = useQuery(
        [classroomId, queryParams],
        () =>
            executeRequest({
                operation: getStudentTrainingsByClassroomId,
                params: queryParams,
            }),
        {
            ...useQueryOptions,
            enabled: !!classData?.classroom,
        },
    );

    useEffect(() => {
        if (error) {
            const msg =
                errorMessages[`${error.path}::${error.statusCode}`] || errorMessages.default;
            getTrainingsErrorSet(msg);
        }
    }, [error]);

    /**
     * Currently we are getting all training from the activity service.
     * This has some complications.
     *
     * 1. A training record can reference old lab arns
     * 2. A training record may be in an error state
     * 3. We do not keep state, each student has more than one "active" lab, this is because
     * we are not onboarded to the labs hook notification. https://sim.amazon.com/issues/BKR-2236
     */

    const studentTrainingsByCurrentLabId =
        !isLoading &&
        !getTrainingsError &&
        data &&
        data.getStudentTrainingsByClassroomId.studentTrainings;

    const appLayoutLabels = {
        navigationClose: formatMessage(messages.closeNavigation),
        navigationToggle: formatMessage(messages.openNavigation),
    };

    const courseTitle = classData?.course?.title;
    return !content ? null : (
        <Fragment>
            <Helmet>
                <title>
                    {formatMessage(messages.pageTitle)} - {`${translatedLabTitle} ${labNumber}`}
                </title>
            </Helmet>
            <HeroBanner title={courseTitle} />
            <AppLayout
                navigation={<LabNavigation content={content} courseTitle={courseTitle} />}
                navigationWidth={160}
                toolsHide
                contentType='table'
                labels={appLayoutLabels}
                content={
                    <div>
                        <Breadcrumbs
                            items={[
                                classesPageBreadcrumb(formatMessage),
                                classDetailPageBreadcrumb(formatMessage, classroomId),
                                { text: formatMessage(messages.sectionTitle) },
                            ]}
                        />
                        <Alert {...alertBannerItem} data-testid='provisioning-alert'>
                            {alertBannerItem?.content}
                        </Alert>
                        <Alert {...regionBannerItem} data-testid='region-alert'>
                            {regionBannerItem?.content}
                        </Alert>
                        <Alert {...warningBannerItem} data-testid='warning-alert'>
                            {warningBannerItem?.content}
                        </Alert>
                        <SectionHeader
                            title={removeCourseNameFromLabTitle(currentLab?.title, courseTitle)}
                            variant='h1'
                            tagOverride='h2'
                        />
                        <Box color='text-body-secondary' margin={{ bottom: 'l' }}>
                            {formatMessage(messages.sectionSubheader)}
                        </Box>
                        <IngressTable
                            labId={labId}
                            loading={isLoading}
                            langLocale={classroom?.langLocale}
                            studentTrainings={
                                studentTrainingsByCurrentLabId &&
                                studentTrainingsByCurrentLabId.length > 0
                                    ? studentTrainingsByCurrentLabId
                                    : []
                            }
                            trainingRefetch={refetch}
                            trainingLoading={isLoading}
                            hasStudentRoster={hasStudentRoster}
                            setAlertBannerItem={alertBannerItemSet}
                            setRegionBannerItem={regionBannerItemSet}
                            setWarningBannerItem={warningBannerItemSet}
                        />
                    </div>
                }
            ></AppLayout>
            {getTrainingsError ? (
                <Modal
                    visible
                    onDismiss={() => getTrainingsErrorSet(false)}
                    header={formatMessage(somethingWentWrongTrainings)}
                    closeAriaLabel={formatMessage(messages.closeModalLabelText)}
                    footer={
                        <Box float='right'>
                            <Button
                                onClick={() => getTrainingsErrorSet(false)}
                                variant='primary'
                                data-testid='lab-training-errors-modal__close-btn'
                            >
                                {formatMessage(close)}
                            </Button>
                        </Box>
                    }
                >
                    {formatMessage(getTrainingsError)}
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default Lab;
