import moment from 'moment';

import { COLUMN_IDS } from './classlistTableConfig';
import { messages } from './ClassTable.messages';

export const classListingTableStateProcessor = (state, action) => {
    let resultingState = state;
    switch (action.type) {
        case 'pageChange':
            if (state.currentPage !== action.event.detail.currentPageIndex) {
                resultingState = {
                    ...state,
                    filtering: {
                        ...state.filtering,
                        updateFilterValues: false,
                    },
                    currentPage: action.event.detail.currentPageIndex,
                };
            }
            break;
        case 'sortingChange':
            resultingState = {
                ...state,
                sorting: {
                    column: action.event.detail.sortingColumn,
                    isDescending: action.event.detail.isDescending,
                },
                filtering: {
                    ...state.filtering,
                    updateFilterValues: false,
                },
            };
            break;
        case 'filterChange':
            resultingState = {
                ...state,
                filtering: {
                    ...state.filtering,
                    activeFilters: {
                        [COLUMN_IDS.startDate]: state.filtering.activeFilters[COLUMN_IDS.startDate],
                        [COLUMN_IDS.endDate]: getDefaultEndDateRange(
                            state.archivedOnlyMode,
                            state.filtering.endDateAbsoluteBoundary,
                        ),
                        ...action.event,
                    },
                    updateFilterValues: true,
                },
                currentPage: 1, //reset to first page
            };
            break;
        case 'filterChange.startDate': {
            /**
             * action is
             * {
             *     type: 'filterChange.startDate'
             *     event: {
             *         amount: number,
             *         unit: TIME_UNIT,
             *         type: 'relative|absolute'
             *     }
             *
             * }
             */
            let newStartDateFilterValue;
            if (action.event.type === 'relative') {
                const referenceMoment = moment(state.filtering.referenceMoment);
                const duration = action.event;
                newStartDateFilterValue = {
                    after:
                        duration.amount < 0
                            ? moment(referenceMoment).add(duration.amount, duration.unit)
                            : referenceMoment,
                    before:
                        duration.amount < 0
                            ? referenceMoment
                            : moment(referenceMoment).add(duration.amount, duration.unit),
                };
            } else {
                newStartDateFilterValue = action.event;
            }
            resultingState = {
                ...state,
                filtering: {
                    ...state.filtering,
                    activeFilters: {
                        ...state.filtering.activeFilters,
                        [COLUMN_IDS.startDate]: newStartDateFilterValue,
                    },
                    updateFilterValues: true,
                },
                currentPage: 1,
            };
            if (!resultingState.filtering.activeFilters.endDate) {
                resultingState.filtering.activeFilters.endDate = getDefaultEndDateRange(
                    state.archivedOnlyMode,
                    state.filtering.endDateAbsoluteBoundary,
                );
            }
            break;
        }
        default:
            throw new Error('unknown action');
    }
    return resultingState;
};

export const getClassListingTableEventDispatcher = (dispatch, notificationStateUpdater, intl) => {
    const dispatcher = (eventType, event) => {
        dispatch({
            type: eventType,
            event,
        });
    };
    const notificationHandler = (type, header, content) => {
        notificationStateUpdater([
            {
                header,
                content,
                type,
                dismissible: true,
                dismissLabel: intl.formatMessage(messages.dismissNotification),
                ariaRole: type === 'error' ? 'alert' : 'status',
                onDismiss: () => notificationStateUpdater([]),
            },
        ]);
    };

    return {
        listingFilterChange: (event) => dispatcher('filterChange', event),
        startDateFilterChange: (event) => dispatcher('filterChange.startDate', event),
        sortingChange: (event) => dispatcher('sortingChange', event),
        paginationChange: (event) => dispatcher('pageChange', event),
        notifyError: (content, header) => notificationHandler('error', header, content),
        notifyWarning: (content, header) => notificationHandler('warning', header, content),
        notifyInfo: (content, header) => notificationHandler('info', header, content),
    };
};

export const classListingFilterEventBuilder = {
    newRelativeStartDateEvent: ({ amount, unit }) => {
        return {
            type: 'relative',
            amount,
            unit,
        };
    },

    newAbsoluteStartDateEvent: ({ afterMoment, beforeMoment }) => {
        return {
            type: 'absolute',
            after: afterMoment,
            before: beforeMoment,
        };
    },
};

export const getClassListingTableInitialState = ({ classTableConfig, archivedOnly }) => {
    const referenceMoment = moment(classTableConfig.filterConfig.referenceMoment);
    const defaultSortByColumn = classTableConfig.columnDefinitions.find(
        (col) => col.id === COLUMN_IDS.startDate,
    );
    const { filterConfig } = classTableConfig;

    const startDateAbsoluteBoundary = {
        after: moment(referenceMoment).add(
            filterConfig.boundaries[COLUMN_IDS.startDate].lower.amount,
            filterConfig.boundaries[COLUMN_IDS.startDate].lower.unit,
        ),
        before: moment(referenceMoment).add(
            filterConfig.boundaries[COLUMN_IDS.startDate].upper.amount,
            filterConfig.boundaries[COLUMN_IDS.startDate].upper.unit,
        ),
    };
    const endDateAbsoluteBoundary = {
        after: moment(referenceMoment).add(
            filterConfig.boundaries[COLUMN_IDS.endDate].lower.amount,
            filterConfig.boundaries[COLUMN_IDS.endDate].lower.unit,
        ),
        before: moment(referenceMoment).add(
            filterConfig.boundaries[COLUMN_IDS.endDate].upper.amount,
            filterConfig.boundaries[COLUMN_IDS.endDate].upper.unit,
        ),
    };
    const initialStartDateRange = {
        after: moment(referenceMoment).add(
            filterConfig.initialOffset[COLUMN_IDS.startDate].after.amount,
            filterConfig.initialOffset[COLUMN_IDS.startDate].after.unit,
        ),
        before: moment(referenceMoment).add(
            filterConfig.initialOffset[COLUMN_IDS.startDate].before.amount,
            filterConfig.initialOffset[COLUMN_IDS.startDate].before.unit,
        ),
    };

    return {
        sorting: {
            column: defaultSortByColumn,
            isDescending: archivedOnly ? true : false,
        },
        filtering: {
            activeFilters: {
                [COLUMN_IDS.courseTitle]: [],
                [COLUMN_IDS.startDate]: initialStartDateRange,
                [COLUMN_IDS.endDate]: getDefaultEndDateRange(archivedOnly, endDateAbsoluteBoundary),
                [COLUMN_IDS.country]: [],
                [COLUMN_IDS.instructor]: [],
                [COLUMN_IDS.createdBy]: null,
            },
            startDateAbsoluteBoundary,
            endDateAbsoluteBoundary,
            initialStartDateRange,
            referenceMoment,
            updateFilterValues: true,
            filterAttributesToUpdate: [COLUMN_IDS.courseTitle, COLUMN_IDS.country],
        },
        currentPage: 1,
        archivedOnlyMode: archivedOnly,
    };
};

const getDefaultEndDateRange = (archivedOnly, endDateAbsoluteBoundary) => {
    if (archivedOnly) {
        return {
            after: moment(endDateAbsoluteBoundary.after),
            before: moment(),
        };
    } else {
        return {
            after: moment(),
            before: moment(endDateAbsoluteBoundary.before),
        };
    }
};

/**
 * exporting reducer for testing
 */
export const reducerForTest = {
    classListingTableStateProcessor,
};
