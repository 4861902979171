import { Modal } from '@amzn/awsui-components-react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { Breadcrumbs, PageWrapper, ClassForm, GilmoreHealthBanner } from '@/components';
import { createClassroom, addInstructorsToClassroom } from '@/modules';
import localStoreageDB from '@/modules/localStorageDb';
import { useLocale } from '@/utils';
import { classCreatePageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';

import messages from './ClassCreatePage.messages';

const errorMessages = {
    'createClassroomV2::409': messages.createClass409Error,
    default: messages.noCreateContent,
};

const ClassCreatePage = () => {
    const { formatMessage } = useIntl();
    const [createClassError, createClassErrorSet] = useState();
    const [, setLocale] = useLocale();
    const selectedLocale = localStoreageDB.selectedLocale();

    useEffect(() => {
        setLocale({
            locale: selectedLocale,
        });
    }, [setLocale, selectedLocale]);

    const onSubmit = async (data) => {
        const payload = await createClassroom(data);
        if (data?.instructors?.length) {
            try {
                await addInstructorsToClassroom(
                    data.instructors,
                    payload.classroomId,
                    data.classroom.providerArn,
                );
            } catch (err) {
                const errObj = JSON.parse(err);
                errObj.classroomId = payload.classroomId;
                throw JSON.stringify(errObj);
            }
        }
        return payload;
    };

    const handleError = (err) => {
        const msg = errorMessages[`${err.path}::${err.statusCode}`] || errorMessages.default;
        createClassErrorSet(msg);
    };

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <GilmoreHealthBanner />
            <Breadcrumbs
                items={[
                    classesPageBreadcrumb(formatMessage),
                    classCreatePageBreadcrumb(formatMessage),
                ]}
            />
            <ClassForm onSubmit={onSubmit} onError={handleError} />
            {createClassError ? (
                <Modal
                    visible
                    data-testid='class-create-modal'
                    header={formatMessage(messages.noCreateHeader)}
                    onDismiss={() => createClassErrorSet(null)}
                    closeLabel={formatMessage(messages.closeModalButtonLabel)}
                >
                    {formatMessage(createClassError)}
                </Modal>
            ) : null}
        </PageWrapper>
    );
};

export default ClassCreatePage;
