import {
    Container,
    DateRangePicker,
    FormField,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import moment from 'moment-timezone';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { TimeZoneSelect } from '@/components';
import {
    isInvalidDateDiff,
    isDateTimeInPast,
    unixToDateString,
    isEndDateEnabled,
    isSameDay,
    isoDateTimeToUnix,
    isInvalidDateRange,
} from '@/utils/timestamp-utils';

import { messages } from '../ClassForm.messages';
import { handleFormValueChange, isClassDurationLessThanRecommended } from '../ClassForm.utils';
import { showErrorMessage } from '../FieldErrors';

import '../ClassForm.css';

const ClassTime = ({
    fieldsInvalid = {},
    timezone,
    setClassData,
    dateTimeData,
    setDateTimeData,
    hasClassStarted,
    hasClassExpired,
    isNewClass,
    isJamOnly,
    startsOn,
    endsOn,
    triggerDurationWarningModal,
    durationWarningAcknowledged,
    isGrimsbyClass,
}) => {
    const { formatMessage, locale } = useIntl();
    const isFirstRun = useRef(true);

    const grimsbyEditsDisabled = !!isGrimsbyClass && !isNewClass;
    const startDateHasChanged = !isSameDay(
        moment.unix(startsOn || 0),
        moment(dateTimeData.startDate),
    );
    const endDateHasChanged = !isSameDay(moment.unix(endsOn || 0), moment(dateTimeData.endDate));
    const inputsHaveChanged = startDateHasChanged || endDateHasChanged;

    const classDurationIsTooShort =
        inputsHaveChanged &&
        !durationWarningAcknowledged &&
        isClassDurationLessThanRecommended(dateTimeData.startDate, dateTimeData.endDate);

    const isValidRange = ({ startDate, endDate }) => {
        const [startD, _startT, endD] = [startDate, endDate].reduce(
            (acc, date) => [...acc, ...date.split('T')],
            [],
        );
        if (!startD) {
            return { valid: false, errorMessage: formatMessage(messages.startDateRequired) };
        } else if (!endD) {
            return { valid: false, errorMessage: formatMessage(messages.endDateRequired) };
        }

        const endDateInPast = !hasClassStarted && isDateTimeInPast(endDate, timezone);
        const endDateTooFar = isNewClass && isInvalidDateDiff(startDate, endDate);
        const endDateExtensionTooFar =
            !isNewClass && isInvalidDateDiff(unixToDateString(endsOn, timezone), endDate);
        const endDateTimeReduced = hasClassStarted && isoDateTimeToUnix(endDate, timezone) < endsOn;
        const endBeforeStart = isInvalidDateRange(startDate, endDate);

        if (endBeforeStart) {
            return { valid: false, errorMessage: formatMessage(messages.startEndTimeInvalid) };
        } else if (endDateInPast) {
            return { valid: false, errorMessage: formatMessage(messages.endDateEndDatePassed) };
        } else if (endDateTooFar) {
            return { valid: false, errorMessage: formatMessage(messages.endDateDurationExceeded) };
        } else if (endDateExtensionTooFar) {
            return {
                valid: false,
                errorMessage: formatMessage(messages.endDateExtensionInvalid, {
                    date: unixToDateString(endsOn, timezone, 5),
                }),
            };
        } else if (endDateTimeReduced) {
            return { valid: false, errorMessage: formatMessage(messages.endDateReductionInvalid) };
        }

        return { valid: true };
    };

    const handleRangeChange = ({ detail: { value } }) => {
        const { startDate = '', endDate = '' } = value ?? {};
        if (hasClassStarted) {
            // prevent start date time edits
            setDateTimeData({ startDate: dateTimeData.startDate, endDate });
        } else {
            setDateTimeData({ startDate, endDate });
        }
    };

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (classDurationIsTooShort && (isNewClass || inputsHaveChanged)) {
            triggerDurationWarningModal();
        }
    }, [classDurationIsTooShort, isNewClass, inputsHaveChanged, triggerDurationWarningModal]);

    return (
        <Container header={<Header variant='h2'>{formatMessage(messages.classTimeHeader)}</Header>}>
            <SpaceBetween direction='vertical'>
                <FormField
                    stretch
                    label={formatMessage(messages.timeZoneLabel)}
                    errorText={showErrorMessage(formatMessage, {
                        fieldsInvalid,
                        property: 'timezone',
                    })}
                >
                    <TimeZoneSelect
                        invalid={!!fieldsInvalid.timezone}
                        disabled={hasClassStarted || hasClassExpired || grimsbyEditsDisabled}
                        selected={timezone}
                        onChange={(e) => {
                            handleFormValueChange({
                                value: e.detail.selectedOption.value,
                                setData: setClassData,
                                keyPath: 'timezone',
                            });
                        }}
                    />
                </FormField>
                {!isJamOnly && (
                    <FormField
                        stretch
                        label={formatMessage(messages.dateRangeLabel)}
                        description={formatMessage(messages.endDateDescription)}
                    >
                        <DateRangePicker
                            rangeSelectorMode='absolute'
                            ariaRequired
                            readOnly={hasClassExpired || grimsbyEditsDisabled}
                            invalid={!!fieldsInvalid.endDate}
                            disabled={hasClassExpired || grimsbyEditsDisabled}
                            locale={locale}
                            isValidRange={isValidRange}
                            value={{ type: 'absolute', ...dateTimeData }}
                            i18nStrings={{
                                todayAriaLabel: formatMessage(messages.todayLabel),
                                nextMonthAriaLabel: formatMessage(messages.nextMonthLabel),
                                previousMonthAriaLabel: formatMessage(messages.previousMonthLabel),
                            }}
                            onChange={handleRangeChange}
                            isDateEnabled={(date) => {
                                if (isNewClass) return true;
                                return isEndDateEnabled(
                                    unixToDateString(endsOn, timezone),
                                    moment(date).format(),
                                    hasClassStarted,
                                );
                            }}
                        />
                    </FormField>
                )}
            </SpaceBetween>
        </Container>
    );
};

export default ClassTime;
