import de from './de-DE.json';
import en from './en-US.json';
import es from './es-ES.json';
import fr from './fr-FR.json';
import id from './id-ID.json';
import it from './it-IT.json';
import ja from './ja-JP.json';
import ko from './ko-KR.json';
import pt from './pt-BR.json';
import th from './th-TH.json';
import zhCN from './zh-Hans-CN.json';
import zhTW from './zh-Hant-TW.json';

export const supportedLocales = [
    'en-US',
    'es-ES',
    'es-LA',
    'ja-JP',
    'ko-KR',
    'zh-CN',
    'fr-FR',
    'id-ID',
    'it-IT',
    'th-TH',
    'de-DE',
    'pt-BR',
];

const locales = {
    'de-DE': de,
    'en-US': en,
    'es-ES': es,
    'es-LA': es,
    'ja-JP': ja,
    'it-IT': it,
    'fr-FR': fr,
    'id-ID': id,
    'pt-BR': pt,
    'th-TH': th,
    'ko-KR': ko,
    'zh-CN': zhCN,
    'zh-Hant-TW': zhTW,
};

export default locales;
