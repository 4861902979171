import { Box } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';

import { useUserInfo } from '@/utils';

import { messages } from './UserKeyDisplay.messages';

import './UserKeyDisplay.scss';

const UserKeyDisplay = () => {
    const { userKey } = useUserInfo();
    const { formatMessage } = useIntl();
    if (!userKey) return null;
    return (
        <Box data-testid='user-key-display'>
            {formatMessage(messages.userKey)} {userKey}
        </Box>
    );
};

export default UserKeyDisplay;
