import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    manageLabsBtnLabel: {
        id: 'labCard.btn.label',
        defaultMessage: 'Manage Student Labs',
    },
    manageLabsBtnAriaIndex: {
        id: 'labCard.btn.label.index',
        defaultMessage: 'Lab {index}',
    },
});

export default messages;
